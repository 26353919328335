
import {defineComponent} from "vue";
import {themeName} from "@/core/helpers/documentation";
import CodeHighlighter from "@/view/content/highlighters/CodeHighlighter.vue";

export default defineComponent({
  name: "line-height",
  components: {
    CodeHighlighter,
  },
  setup() {
    const lineHeightValues = {
      "1": 1,
      sm: 1.25,
      base: 1.5,
      lg: 1.75,
      xl: 2,
      xxl: 2.25,
    };

    return {
      themeName,
      lineHeightValues,
    };
  },
});
