
import {defineComponent} from "vue";
import {themeName} from "@/core/helpers/documentation";
import CodeHighlighter from "@/view/content/highlighters/CodeHighlighter.vue";

export default defineComponent({
  name: "font-size",
  components: {
    CodeHighlighter,
  },
  setup() {
    return {
      themeName,
    };
  },
});
