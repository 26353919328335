
import {defineComponent} from "vue";
import CodeHighlighter from "@/view/content/highlighters/CodeHighlighter.vue";

export default defineComponent({
  name: "opacity",
  components: {
    CodeHighlighter,
  },
  setup() {
    const opacityValues = [0, 5, 10, 15, 20, 25, 50, 75, 100];

    return {
      opacityValues,
    };
  },
});
